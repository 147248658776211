import React, { useState, useEffect } from "react";
import logo from "../images/sai-krupa-ambulance-logo.png";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed-top ${isScrolled ? "scrolled" : ""}`}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/" onClick={closeMenu}>
            <img src={logo} alt="" />
          </Link>
        </div>
        <input
          type="checkbox"
          id="menu-btn"
          checked={isOpen}
          onChange={toggleMenu}
        />
        <input
          type="checkbox"
          id="close-btn"
          checked={!isOpen}
          onChange={toggleMenu}
        />
        <ul className={`nav-links ml-auto mb-0${isOpen ? " open" : ""}`}>
          <label htmlFor="close-btn" className="btn close-btn">
            <i className="ri-menu-3-line" style={{ color: "#ff6210" }}></i>
          </label>

          <li>
            <Link to="/" style={{ color: "#ff6210" }} onClick={closeMenu}>
              Home
            </Link>
          </li>

          <li>
            <Link to="/about" style={{ color: "#ff6210" }} onClick={closeMenu}>
              About
            </Link>
          </li>

          <li>
            <Link
              to="/contact"
              style={{ color: "#ff6210" }}
              onClick={closeMenu}
            >
              Contact
            </Link>
          </li>

          <li>
            <Link
              to="https://wa.me/+919391452735"
              className="green_fixed_button_nav"
            >
              <span style={{ color: "white" }}> <i class="ri-whatsapp-line"></i> Whatsapp</span>
            </Link>
          </li>
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn">
          <i className="ri-menu-3-line" style={{ color: "#ff6210" }}></i>
        </label>
      </div>
    </nav>
  );
};

export default Navbar;
