import React from "react";
import "./Footer.css";
import logo from "../../images/sai-krupa-ambulance-logo-white.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img src={logo} className="img-fluid" />
            <p>
              Our dedication to expert precision is evident, guiding you from
              critical moments to a space of focused care. Our ambulance
              services epitomize Speed, ensuring that with a single call, vital
              care is immediately accessible. Acknowledging that every second is
              crucial, we harness the urgency of professional care, knowing that
              your health depends on swift action.
            </p>
            <p className="copyRight">Copyright © 2024 Sai Krupa Ambulance</p>
          </div>
          {/* <div className="col-md-4">
            <h4>QUICK LINKS</h4>
            <ul>
              <li>Home</li>
              <li>About</li>
              <li>Contact Us</li>
            </ul>
          </div>
          <div className="col-md-4">
            <h4>BOOK AMBULANCE</h4>
            <a href="tel:+919391452735" className="icon">
              <button
                onClick={() => (window.location.href = "tel:+919391452735")}
              >
                <i class="ri-phone-fill"></i> Call : +91 93914 52735
              </button>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;
