import React ,{useEffect} from "react";
import aboutImg from "../../images/home2.jpeg";
import g1 from "../../images/g1.jpeg";
import g2 from "../../images/g2.jpeg";
import g3 from "../../images/g3.jpeg";
import g4 from "../../images/g4.jpeg";
import g5 from "../../images/g5.jpeg";
import g6 from "../../images/g6.jpeg";
import g7 from "../../images/g7.jpeg";
import g8 from "../../images/g8.jpeg";
import g9 from "../../images/g9.jpeg";

import AOS from "aos";
import "aos/dist/aos.css";


const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div>
      <div className="floating-icons">
        <a href="tel:+919391452735" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919391452735")}
            className="red_fixed_button"
          >
            <i class="ri-phone-line"></i> +91 9391452735
          </button>
        </a>
        <a href="https://wa.me/+919391452735" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="ri-whatsapp-line"></i> Whatsapp
          </button>
        </a>
      </div>

      <section className="home_sec3" style={{ marginTop: "60px" }}>
        <h1>About Us</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <img src={aboutImg} className="img-fluid" />
            </div>
            <div className="col-md-6 m-auto">
              <p>
                One of the leading ambulance services in Hyderabad and
                Secundrabad, Sai Krupa Ambulance Services specializes in the
                transfer of critically ill patients while utilizing
                sophisticated life support. All of the hospitals in Hyderabad
                and Secunderabad, including Osmania General Hospital, Gandhi
                Hospital, NIMS Hospital, Apollo Hospital, Medwin, AIG Hospital,
                Continental Hospital, etc., are getting patients from us.
              </p>
              <p>
                Our ambulance has oxygen and a ventilator, so it can safely
                transport critically ill patients who require various forms of
                life support or who are experiencing cardiac emergencies like an
                acute myocardial infarction (heart attack), angina, arrhythmia,
                etc. We have experience transferring these patients from
                hospitals to their homes or places of origin.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec5">
        <h1>Gallery</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g1} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g2} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g3} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g4} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g5} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g6} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g7} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g8} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g9} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec6">
        <div className="container">
          <div data-aos="zoom-in" data-aos-duration="2000">
            <div>
              <h1>Ambulance Helpline Number</h1>
            </div>
            <div className="">
              <a href="tel:+919391452735" className="icon">
                <button
                  onClick={() => (window.location.href = "tel:+919391452735")}
                >
                  <i class="ri-phone-fill"></i> Call : +91 93914 52735
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
