import React, { useEffect } from "react";
import "./Contact.css";
import Vcard from "../../images/visitingcard.jpeg";

import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div>
      <div className="floating-icons">
        <a href="tel:+919391452735" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919391452735")}
            className="red_fixed_button"
          >
            <i class="ri-phone-line"></i> +91 9391452735
          </button>
        </a>
        <a href="https://wa.me/+919391452735" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="ri-whatsapp-line"></i> Whatsapp
          </button>
        </a>
      </div>

      <div className="contact_sec1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto order-md-0 order-1">
              <p>
                <strong>Looking For Best Ambulance Service in Hyderabad</strong>
              </p>
              <h3>Schedule Ambulance Booking</h3>
              <p>
                Get in touch with us now and book Ambulance services for Local
                or Outstation Ambulance or Dead Body Freezer Box Services.
              </p>

              <div></div>
            </div>
            <div className="col-md-6 order-md-1 order-0">
              <img src={Vcard} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <section className="home_sec6">
        <div className="container">
          <div data-aos="zoom-in" data-aos-duration="2000">
            <div>
              <h1>Ambulance Helpline Number</h1>
            </div>
            <div className="">
              <a href="tel:+919391452735" className="icon">
                <button
                  onClick={() => (window.location.href = "tel:+919391452735")}
                >
                  <i class="ri-phone-fill"></i> Call : +91 93914 52735
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
