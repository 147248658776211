import React, { useEffect, useState } from "react";
import "./Home.css";
import aboutImg from "../../images/home2.jpeg";
import g1 from "../../images/g1.jpeg";
import g2 from "../../images/g2.jpeg";
import g3 from "../../images/g3.jpeg";
import g4 from "../../images/g4.jpeg";
import g5 from "../../images/g5.jpeg";
import g6 from "../../images/g6.jpeg";
import g7 from "../../images/g7.jpeg";
import g8 from "../../images/g8.jpeg";
import g9 from "../../images/g9.jpeg";

import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  // const handleBookAmbulance = () => { };
  const handleBookAmbulance = () => {
    // Create an object with the data to send
    const data = {
      name: fullName,
      number: mobileNumber,
    };
    if (fullName !== "" && mobileNumber !== "" && mobileNumber.length === 10) {
      // Your code here

      // Make a POST request
      fetch("https://saikrupaambulance-server.onrender.com/alert", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      });
      toast.success("Thanks for reaching out !");
    } else {
      toast.error("Please fill all details");
    }
    // .then((data) => {
    //   console.log("Response:", data);
    //   // Handle success response here
    // })
    // .catch((error) => {
    //   console.error("There was a problem with the request:", error);
    //   // Handle error here
    // });
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page when the component mounts
  }, []);
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);
  return (
    <div style={{ marginTop: "100px" }}>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
        theme="dark"
        className="custom-toast-container"
      />

      <div className="floating-icons">
        <a href="tel:+919391452735" className="icon">
          <button
            onClick={() => (window.location.href = "tel:+919391452735")}
            className="red_fixed_button"
          >
            <i class="ri-phone-line"></i> +91 9391452735
          </button>
        </a>
        <a href="https://wa.me/+919391452735" className="icon">
          <button className="ml-2 green_fixed_button ">
            <i class="ri-whatsapp-line"></i> Whatsapp
          </button>
        </a>
      </div>

      <section className="home_sec1">
        <div className="container">
          <div className="row">
            <div className="col-md-6 home_sec01 m-auto order-md-0 order-1">
              <h2> Your safety in critical moments</h2>
              <p>
                where the promise of outstanding care and compassion greets each
                journey. Our devoted group of medical experts is committed to
                offering trustworthy and timely ambulance services.
              </p>
            </div>
            <div className="col-md-6 home_sec02  m-auto order-md-1 order-0">
              <h4>GET A CALL BACK IN JUST 5 SECONDS</h4>

              <form>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />

                <button onClick={handleBookAmbulance}>Book Ambulance</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec2">
        <div className="container">
          <div data-aos="zoom-in" data-aos-duration="2000">
            <h1>
              Book Ambulance Service in Hyderabad 24*7 Available with all
              Medical Equipments
            </h1>

            <a href="tel:+919391452735" className="icon">
              <button
                onClick={() => (window.location.href = "tel:+919391452735")}
              >
                <i class="ri-phone-fill"></i> Call : +91 93914 52735
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="home_sec3">
        <div data-aos="fade-up" data-aos-duration="1000">
          <h1>About Us</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={aboutImg} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6 m-auto">
              <div data-aos="fade-up" data-aos-duration="2000">
                <p>
                  One of the leading ambulance services in Hyderabad and
                  Secundrabad, Sai Krupa Ambulance Services specializes in the
                  transfer of critically ill patients while utilizing
                  sophisticated life support. All of the hospitals in Hyderabad
                  and Secunderabad, including Osmania General Hospital, Gandhi
                  Hospital, NIMS Hospital, Apollo Hospital, Medwin, AIG
                  Hospital, Continental Hospital, etc., are getting patients
                  from us.
                </p>
                <p>
                  Our ambulance has oxygen and a ventilator, so it can safely
                  transport critically ill patients who require various forms of
                  life support or who are experiencing cardiac emergencies like
                  an acute myocardial infarction (heart attack), angina,
                  arrhythmia, etc. We have experience transferring these
                  patients from hospitals to their homes or places of origin.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec4">
        <div data-aos="fade-up" data-aos-duration="1000">
          <h1>Why Sai Krupa Ambulance?</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="1500">
                <div>
                  <i class="ri-map-pin-add-line"></i>
                </div>
                <div>
                  <h6>10 Mins Reach Location</h6>
                  <p>Any Enquiry Respond Within 10 Seconds</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="1500">
                <div>
                  <i class="ri-shield-cross-line"></i>
                </div>
                <div>
                  <h6>Safe Reach</h6>
                  <p>Safe Reach Within 30 Min</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="1500">
                <div>
                  <i class="ri-history-line"></i>
                </div>
                <div>
                  <h6>24x7 Avaliable</h6>
                  <p>Our Ambulances Are Avaliable Any Time</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec5">
        <h1>Gallery</h1>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g1} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g2} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g3} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g4} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g5} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g6} className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g7} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g8} className="img-fluid" />
              </div>
            </div>
            <div className="col-md-4">
              <div data-aos="fade-up" data-aos-duration="2000">
                <img src={g9} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home_sec6">
        <div className="container">
          <div data-aos="zoom-in" data-aos-duration="2000">
            <div>
              <h1>Ambulance Helpline Number</h1>
            </div>
            <div className="">
              <a href="tel:+919391452735" className="icon">
                <button
                  onClick={() => (window.location.href = "tel:+919391452735")}
                >
                  <i class="ri-phone-fill"></i> Call : +91 93914 52735
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
